<template>
  <div>
    <div class="p-pegas">
      <div class="caz-blocks-sarcho-title pt-3 pb-3 z-i pl-3  " :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.report_card")}}</div>
        <div class="block-sarche w90__mobile">
          <div class="header__search my__header__search mobile__column d-flex">
            <select-staff
              v-model="filterForm.id"
              :class="mode ? 'input__day' : 'input__night'"
              class="journal__mobile"
              :id="filterForm.id"
            ></select-staff>
            <select-branch
              :id="filterForm.branch_id"
              class="journal__mobile"
              :class="mode ? 'input__day' : 'input__night'"
              v-model="filterForm.branch_id"
            ></select-branch>
            <el-date-picker
              v-model="filterForm.date"
              :size="'small'"
              type="month"
              format="MMMM yyyy"
              value-format="dd.MM.yyyy"
              class="journal__mobile"
              :class="mode ? 'input__day' : 'input__night'"
              placeholder="Pick a month"
              @change="triggerChanges"
              :clearable="false"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <div class="d-flex journal__mobile">
              <div class="coKvadrat" :class="mode ? 'content__titleday' : 'content__titlenight'">
                <span class="tabelColor2"></span>
                <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.unrevealed")}}</b>
              </div>
              <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
                <span  class="tabelColor3"></span>
                <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("message.manual")}}</b>
              </div>
              <div :class="mode ? 'text' : 'textnight'" class="coKvadrat">
                <span  class="tabelColor5"></span>
                <b class="attendance__text" :class="mode ? 'text' : 'textnight'">{{$t("Дистанционная работа")}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="tableScrol">
        <table
          class="table ali table-img table-my-code tableRowWight no-hover table__nurillo"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th rowspan="3" class="sticiy p-0">
                <div class="for_name top-struktur b-r-color-main">№</div>
              </th>
              <th rowspan="3" class="sticiy_2 p-0"> 
                <div class="for_name top-struktur"> {{$t("message.staff")}} </div>
              </th>
              <!-- <th rowspan="3" class="sticiy_3">{{$t("message.position")}}</th> -->
              <th :colspan="range.first" class="t-top-title tt-top-day">
                {{$t("message.records_of_the_month")}}
              </th>
              <th rowspan="3">{{$t("message.general_working_day")}}</th>
              <th rowspan="3">{{$t("message.general_working_hour")}}</th>
            </tr>
            <tr>
              <td :class="mode ? 'content__titleday' : 'content__titlenight'" v-for="(day, index) in range.first" :key="'first' + index">
                <div class="for_name tt-top-day">
                  {{ day }}
                </div>
              </td>
            </tr>
            <tr>
              <td
              :class="mode ? 'content__titleday' : 'content__titlenight'"
                v-for="(day, index) in rangeFunction(range.first + 1, range.last)"
                :key="'first' + index"
              >
                <div class="for_name tt-top-day">
                  {{ day }}
                </div>
              </td>
              <td v-if="isEvenOrOdd == 'odd'"><div class="for_name">
                XXX
              </div></td>
            </tr>
          </thead>
          <tbody v-for="(staff, index) in list" :key="'index-' + index" class="hover-bg-color">
              <tr >
              <td class="sticiy" rowspan="2" @dblclick="profile(staff)"> <div class="for_name nam-p">{{ staff.id }}</div></td>
              <td class="sticiy_2" rowspan="2" @dblclick="profile(staff)"><div class="for_name">
                {{ staff.name + " " + staff.last_name }}
               <br>
               <span class="dol-name"> {{ staff.position ? staff.position.name : "" }}</span>
              </div></td>
              <!-- <td rowspan="2" class="sticiy_3"><div class="for_name"></div></td> -->
              <td v-for="(day, index) in range.first"
                :key="'dailyReport' + index"
                :style="
                (staff.daily_reports.length != 0 && staff.daily_reports[index] && staff.daily_reports[index].is_manual==true)
                ? 'background-color:#af4562 !important; color:#fff !important'
                : ''"
                :class="
                  staff.daily_reports.length != 0
                    ? staff.daily_reports[index].state
                    : 'NO_CHECK_IN'
                "
                @dblclick="showInfoList(staff.daily_reports[index].id, staff.id, (staff.daily_reports.length&&staff.daily_reports[index]?staff.daily_reports[index].date:''))"
              > 
              <div class="over">
                <div class="workly_hour" :title="'Рабочее время '+(staff.daily_reports.length&&staff.daily_reports[index]?staff.daily_reports[index].date:'')">  {{staff.daily_reports.length != 0 && staff.daily_reports[index].total_working_hour != 0
                  ? (parseInt(staff.daily_reports[index].total_working_hour) +$t('message.hour') +' '+ ((staff.daily_reports[index].total_working_hour-parseInt(staff.daily_reports[index].total_working_hour)) *60).toFixed(0)+$t('message.minut') ):'0'}}
                </div>
                <div :title="'Дистанционная работа '+(staff.daily_reports.length&&staff.daily_reports[index]?staff.daily_reports[index].date:'')" class="workly_online_or_additional" :class="(staff.daily_reports.length != 0 && staff.daily_reports[index].online_work_hour &&staff.daily_reports[index].additional_work_hour)?'yellow':''">
                  {{
                    staff.daily_reports.length != 0 && staff.daily_reports[index].online_work_hour &&staff.daily_reports[index].additional_work_hour
                      ? (parseInt(staff.daily_reports[index].online_work_hour+staff.daily_reports[index].additional_work_hour) +
                      $t('message.hour') +" "+ (((staff.daily_reports[index].online_work_hour+staff.daily_reports[index].additional_work_hour) - parseInt(staff.daily_reports[index].online_work_hour+staff.daily_reports[index].additional_work_hour)) *60).toFixed(0)+$t('message.minut') )
                      : "0"
                  }}
                </div>
              </div>
               
              </td>
  
              <td rowspan="2">
                {{
                  staff.monthly_reports.length != 0
                    ? staff.monthly_reports[0].total_working_day
                    : ""
                }}
              </td>
              <td rowspan="2">
                {{
                  staff.monthly_reports.length != 0
                    ? staff.monthly_reports[0].total_working_hour
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td
                v-for="(day, index) in rangeFunction(range.first + 1, range.last)"
                :key="'first' + index"
                :style="staff.daily_reports.length != 0 && staff.daily_reports[range.first + index]&&staff.daily_reports[range.first + index].is_manual
                ? 'background-color:#af4562 !important; color:#fff !important;'
                : ''"
  
                :class="
                staff.daily_reports.length != 0
                  ? staff.daily_reports[range.first + index].state
                  : 'NO_CHECK_IN'
              "
              @dblclick="showInfoList(staff.daily_reports[range.first + index].id, staff.id, (staff.daily_reports[range.first + index]?staff.daily_reports[range.first + index].date:'') )"
              >
              <div class="over">
                <div class="workly_hour" :title="'Рабочее время '+(staff.daily_reports.length&&staff.daily_reports[range.first + index]?staff.daily_reports[range.first + index].date:'')"> {{
                  staff.daily_reports.length != 0 && staff.daily_reports[range.first + index].total_working_hour !=0
                    ? (parseInt(staff.daily_reports[range.first + index].total_working_hour) +$t('message.hour') +" "+ ((staff.daily_reports[range.first + index].total_working_hour - parseInt(staff.daily_reports[range.first + index].total_working_hour)) *60).toFixed(0)+$t('message.minut') )
                    : "0"
                }}</div>
                <div class="workly_online_or_additional" :title="'Дистанционная работа '+(staff.daily_reports.length&&staff.daily_reports[range.first + index]?staff.daily_reports[range.first + index].date:'')">{{
                    staff.daily_reports.length != 0 && staff.daily_reports[range.first + index].online_work_hour &&staff.daily_reports[range.first + index].additional_work_hour
                      ? (parseInt(staff.daily_reports[range.first + index].online_work_hour+staff.daily_reports[range.first + index].additional_work_hour) +
                      $t('message.hour') +" "+ (((staff.daily_reports[range.first + index].online_work_hour+staff.daily_reports[range.first + index].additional_work_hour) - parseInt(staff.daily_reports[range.first + index].online_work_hour+staff.daily_reports[range.first + index].additional_work_hour)) *60).toFixed(0)+$t('message.minut') )
                      : "0"
                  }}</div>
              </div>
               
              </td>
              <td v-if="isEvenOrOdd == 'odd'">XXX</td>
            </tr>
          </tbody>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
    <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showInfoModal"
        size="40%"
        :with-header="false"
        ref="show-info"
        @opened="drawerOpened('show-info')"
        @closed="drawerClosed('show-info')"
      >
        <div>
          <show-info
            @closeInfoModal="closeInfoModal"
            :child="child"
            ref="show-info"
          ></show-info>
        </div>
      </el-drawer>
  </div>
  </template>
  <script>
  import list from "@/utils/mixins/list";
  import { i18n } from "@/utils/i18n";
  import { mapGetters, mapActions, mapState } from "vuex";
  import selectBranch from "@/components/filters/inventory/select-branch";
  import showInfo from "./components/show-info.vue";
  
  import moment from "moment";
  export default {
    name: "AttendanceReportController",
    mixins: [list],
    components: {
      selectBranch,
      showInfo
    },
    data() {
      return {
        drawerCreate: false,
        drawerShow: false,
        drawerUpdate: false,
        showInfoModal: false,
        filterForm: {},
        loadingData: false,
        reloadList: false,
        loadingComments: false,
        isEvenOrOdd: "odd",
        range: { first: 0, last: 0 },
        child:{},
        pickerOptions: {
            disabledDate(time) {
              return time.getTime() > Date.now() - 8.64e7;
            }
        } 
      };
    },
    created() {
      this.filterForm = JSON.parse(JSON.stringify(this.filter));
      this.triggerChanges(this.filterForm.date);
      this.debouncedFetchData = _.debounce(this.fetchData, 500);
    },
    computed: {
      ...mapGetters({
        list: "attendanceReport/list",
        columns: "attendanceReport/columns",
        pagination: "attendanceReport/pagination",
        statues: "attendanceReport/statues",
        filter: "attendanceReport/filter",
        sort: "attendanceReport/sort",
        mode: "MODE"
      }),
      actions: function () {
        return [];
      },
    },
    methods: {
      ...mapActions({
        updateList: "attendanceReport/index",
        setPagination: "attendanceReport/setPagination",
        updateSort: "attendanceReport/updateSort",
        updateFilter: "attendanceReport/updateFilter",
        updateColumn: "attendanceReport/updateColumn",
        updatePagination: "attendanceReport/updatePagination",
        show: "attendanceReport/show",
        // empty: "attendanceReport/empty",
        delete: "attendanceReport/destroy",
        refreshData: "attendanceReport/refreshData",
      }),
      rangeFunction: function (start, end) {
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
      },
      onlineWorks(arra){
        var text ='Online Works';
        arra.forEach(element => {
          text +="\n Vaqti: "+element.from_time+' - '+element.to_time;
        });
        return  text;
      },
      
      triggerChanges(date) {
        let lastDayOfMonth = moment(date, "DD.MM.YYYY")
          .endOf("month")
          .format("DD");
        lastDayOfMonth = parseInt(lastDayOfMonth);
        if (lastDayOfMonth % 2 == 0) {
          this.range.first = lastDayOfMonth / 2;
          this.range.last = lastDayOfMonth;
          console.log(this.range);
          return (this.isEvenOrOdd = "even");
        }
  
        this.range.first = parseInt(lastDayOfMonth / 2) + 1;
        this.range.last = lastDayOfMonth;
        console.log(this.range);
        this.isEvenOrOdd = "odd";
      },
      closeInfoModal(val) {
        this.showInfoModal = val;
      },
      showInfoList(id, staff_id, date) {
        this.child.id = id;
        this.child.staff_id = staff_id;
        this.child.date = date;
        this.showInfoModal = true;
      },
  
      drawerOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened(this.child);
          }
        }
      },
      profile(model) {
        let route = this.$router.resolve({
          name: "profile",
          params: {
            id: model.id,
          },
        });
  
        window.open(route.href, "_blank");
  
        //    OLD CODE
        //   this.$router.push({
        //     name: "profile",
        //     params: { id: model.id },
        //   });
      },
     
    },
  };
  </script>
  <style>
  .ali thead th,
  .ali thead td,
  .ali tbody td {
    vertical-align: middle;
    text-align: center;
  }
  .boderL {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 3px;
    margin-bottom: 4px;
    text-align: center;
  }
  .tabelColor0 {
    background: #c3c3c3;
  }
  .tabelColor1 {
    background: #a3d5f3;
  }
  .tabelColor2 {
    background: #f39393;
  }
  .NO_CHECK_IN_AND_OUT {
    background: #f39393;
  }
  .tabelColor3 {
    background: #af4562;
  }
  .WAITING {
    background: hsl(66, 80%, 76%);
  }
  
  .tabelColor4 {
    background: #d791f3;
  }
  
  .tabelColor5 {
    background: #3e8bc7;
  }
  .coKvadrat span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 2px;
  }
  .coKvadrat {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .tableRowWight tr th:nth-child(1) {
    width: 35px;
    max-width: 35px;
    /* background-color: red; */
  }
  .tableRowWight tr th:nth-child(4) {
    width: 100px;
    max-width: 100px;
    /* background-color: red; */
  }
  .tableRowWight tr th:nth-child(2) {
    width: 170px;
    max-width: 170px;
    /* background-color: red; */
  }
  .tableRowWight tr th:nth-child(3) {
    width: 250px;
    max-width: 250px;
    /* background-color: red; */
  }
  /* .tableRowWight tr th:nth-child(2) {
      width: 155px;
      max-width: 155px;
  } */
  .tableRowWight tr td:nth-child(23),
  .tableRowWight tr td:nth-child(24),
  .tableRowWight tr td:nth-child(25),
  .tableRowWight tr td:nth-child(26) {
    width: 100px;
    max-width: 100px;
    /* background-color: red; */
  }
  .tableScrol {
    margin-top: -1px;
    width: 100%;
    /* overflow: hidden; */
    height: calc(100vh - 140px);
    overflow-y: scroll;
  }
  .tableScrol table {
    width: 2500px;
  }
  .tableScrol::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  .tableScrol::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  .tableScrol::-webkit-scrollbar-thumb {
    background: #0b4c73d4;
    border: 0px solid #ffffff;
    border-radius: 30px;
  }
  .tableScrol::-webkit-scrollbar-thumb:hover {
    background: #a3d5f3;
  }
  .tableScrol::-webkit-scrollbar-thumb:active {
    background: #a3d5f3;
  }
  .tableScrol::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px solid #ffffff;
    border-radius: 100px;
  }
  .tableScrol::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  .tableScrol::-webkit-scrollbar-track:active {
    background: #ffffff;
  }
  .tableScrol::-webkit-scrollbar-corner {
    background: transparent;
  }
  .table__nurillo th:first-child {
    position:sticky;
    left:0;
    z-index:2;
  }
  .wrapper {
    width: 320px;
    height: 200px;
    overflow: auto;
    position: relative;
  }
  
  
  
  thead, thead tr>th {
    position: sticky;
    background: #fff;
    z-index: 999;
    position: -webkit-sticky;
    position: sticky; 
  }
  
  thead {
    top: 0;
    z-index: 2;
  }
  thead tr>th:first-child {
    z-index: 999;
  }
  .t-top-title{
    background: #d9f3cc !important;
  }
  .sticiy {
    background: #d9f3cc !important;
    position: -webkit-sticky;
    position: sticky; 
    background: #fff;
    left: 0px; 
  }
  .sticiy_2 {
    background: #d9f3cc !important;
    position: -webkit-sticky;
    position: sticky; 
    background: #fff;
    left: 54px; 
  }
  .sticiy_3 {
    position: -webkit-sticky;
    position: sticky; 
    background: #fff;
    left: 224px; 
  }
  /* don't do this */
  .sticiy {
   border-color : black !important;
  }
  .table-my-code td{
    padding: 0 !important;
  }
  .over{
    display: flex;
    justify-content: center;
    align-items: center;
   /* padding: 2px;*/
  }
  .over >div{
    padding: 3px 3px;
    width: 50%;
  
  }
  .for_name{
      padding: 7px 9px;
      font-size: 11px;
      border-left: 1px solid#979797;
      border-right: 1px solid#979797;
      margin-left: -1px;
      margin-right: -1px;
      /* background-color: red; */
  }
  .nam-p{
    padding: 17px 9px;
    border-right: 1px solid#d9f3cc;
  }
  .workly_online_or_additional{
    border-left: 1px solid #dcdcdc;
  }
  .table-img.table-my-code td{
    padding: 0 !important;
  }
  .table__nurillo td, .table__nurillo th{
    border-color: #979797 !important;
  }
  .workly_online_or_additional.yellow{
      background: #3e8bc7;
      color: #e6e6e6;
  }
  span.dol-name {
      background: #399919;
      color: #fff;
      padding: 1px 4px;
      border-radius: 4px;
      margin-top: 5px;
      display: inline-block;
  }
  .z-i{
    position: relative;
      z-index: 999;
      border-bottom: 1px solid #000 !important;
  }
  .hover-bg-color:hover {
    background: #d9f3cc;
  }
  .hover-bg-color:hover>tr>td> div{
    font-weight: 700 !important;
  }
  .hover-bg-color:hover>tr>td>div>div:hover{
    background-color: #aadaff;
    cursor: pointer;
  }
  .top-struktur{
      height: 89px;
      padding: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .p-0{
    padding: 0px !important;
  }
  .b-r-color-main{
    border-right: 1px solid#d9f3cc;
  }
  .tt-top-day{
      border-bottom: 1px solid#979797 !important;
      margin-bottom: -2px;
  }
  </style>
  